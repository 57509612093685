<template>
  <form @submit.prevent="authorize" class="login-form">
    <div class="mb-3">
      <input class='form-control' v-model="data_to_send.login" placeholder="login" />
    </div>
    <div class="mb-3">
      <input
          class='form-control'
        v-model="data_to_send.password"
        type="password"
        placeholder="password"
      />
    </div>
    <div class="text-danger form-errors" v-if="errors.length">
      <p class="small" v-for="(error, i) in errors" :key="i">{{error}}</p>
    </div>
    <div class="mb-3">
      <button class="btn btn-primary">LogIn</button>
    </div>
  </form>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { defineComponent } from "vue";
import {useRouter} from 'vue-router'

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();

    let data_to_send = ref({
      login: "",
      password: "",
    });
    let errors = ref([]);

    const authorize = () => {
      store.dispatch("login", data_to_send.value).then(
        ({ok, data}) => {
          errors.value = [];
          if (!ok) {
            for(const errors_list of Object.values(data)) {
              errors.value = errors.value.concat(errors_list);
            }
          } else {
            router.push({name: 'Generator'})
          }
        }
      );
    };


    return {
      authorize,
      data_to_send,
      errors
    };
  },
});
</script>

<style lang="scss">
  .login-form {
    max-width: 400px;
    width: 100%;
    maring: auto;
    position: relative;
    margin: auto;
    padding-top: 20%;
  }
  .form-errors {
    .small {
      &:not(:last-child) {
        margin-bottom: 0
      }
    }
  }
</style>
